import React from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ObtenerGeoJson from "../hooks/maps";
import { Grid, Checkbox, CircularProgress, Box } from '@material-ui/core';

import { GoogleMap, useJsApiLoader, GroundOverlay, Polygon } from '@react-google-maps/api';


export default function Mapa() {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBO0BcpUOaQ1ZXwwMzCX2hKYIO1uKdKVrM"

    })

    const [response, setResponse] = React.useState({})
    const [loading, setLoading] = React.useState('flex')
    const [map, setMap] = React.useState({})
    const [blueView, setBlueView] = React.useState([])
    const [blue, setBlue] = React.useState([])
    const [red, setRed] = React.useState([])
    const [green, setGreen] = React.useState([])
    const [yellow, setYellow] = React.useState([])
    const [counterOpenShowPrice, setCounterOpenShowPrice] = React.useState(0)
    //  const map = ;
    const onLoad = React.useCallback(async function callback(map) {
        const bounds = new window.google.maps.LatLngBounds({ lat: 32.32393, lng: -117.045608 });
        map.fitBounds(bounds);
        // map.bounds(bounds);
        //debugger
        const response = await ObtenerGeoJson();
        if (response) {
            map.data.addGeoJson(response.featurejson);
            // console.log(response.features);

            //  map.data.setStyle(

            response.features.features.map((feature, index) => {
                //    debugger
                const array = []
                const opt = {
                    path: array,
                    map: map,
                    strokeColor: '#14993c',
                    fillColor: '#14993c',
                    strokeWeight: 1,
                    fillOpacity: 0.5,
                    strokeOpacity: 0.5,
                }



                // var color = '#14993c'; // Green
                // var opacity = 0.3;
                // var stroke = 0.4;


                //const triangleCoords = [{ lng: -117.044496848187, lat: 32.3270950249757 }, { lng: -117.044428158448, lat: 32.3270525153154 }, { lng: -117.044302993699, lat: 32.3271983221835 }, { lng: -117.044397442178, lat: 32.3272567730492 }, { lng: -117.04450383218, lat: 32.3271328371408 }, { lng: -117.044504471715, lat: 32.3271320641412 },];



                feature.geometry.coordinates[0].map((cords) => {
                    let cord = { lng: cords[0], lat: cords[1] }
                    array.push(cord);
                })


                opt.path = array;
                //   console.log(array)
                const lotName = feature.properties.name;
                const lot = response.disponibilidad.find((disp) => disp.Nombre === lotName);



                // debugger
                if (lot) {
                    switch (lot.Estatus) {
                        case 2:
                            opt.strokeColor = '#0D0D9B'; // Blue
                            opt.fillColor = '#0D0D9B'; // Blue
                            var poligono = new window.google.maps.Polygon(opt);

                            new window.google.maps.event.addListener(poligono, 'click', function (event) {
                                //alert the index of the polygon
                              
                                alert("poligono:" + index);
                            });

                            blue.push({ opt: opt, pol: poligono });
                            break;
                        case 6:
                            opt.strokeColor = '#9B0D0D'; // Red
                            opt.fillColor = '#9B0D0D'; // Red

                            var poligono = new window.google.maps.Polygon(opt);
                            red.push({ opt: opt, pol: poligono });
                            break;
                        case 4:
                            opt.strokeColor = '#E6F003'; // Yellow
                            opt.fillColor = '#E6F003'; // Yellow

                            var poligono = new window.google.maps.Polygon(opt);
                            yellow.push({ opt: opt, pol: poligono });
                            break;
                        default:
                            var poligono = new window.google.maps.Polygon(opt);
                            green.push({ opt: opt, pol: poligono });
                            break;
                    }
                }

                // map.data.add({
                //     geometry: new window.google.maps.Data.Polygon([
                //       array
                //     ]),
                // })

                // var poligono = new window.google.maps.Polygon(opt);
                // var poligono = new window.google.maps.Polygon({
                //     path: array,
                //     map: map,
                //     strokeColor: color,
                //     fillColor: color,
                //     strokeWeight: stroke,
                //     fillOpacity: opacity,
                //     strokeOpacity: stroke,
                // });


                // if (lot) {
                //     let blueA = blue;
                //     let redA = red;
                //     let greenA = green;

                //     switch (lot.Estatus) {
                //         case 2:
                //             // Blue// Blue

                //             blueA.push(poligono);
                //             setBlue(blueA);
                //             // poligono.setVisible(this.blue);
                //             break;
                //         case 6:
                //             // Red
                //             // red.push(poligono);
                //             let redA = red;

                //             redA.push(poligono);
                //             setRed(redA);
                //             break;
                //         case 4:


                //             greenA.push(poligono)
                //             setGreen(greenA);
                //             color = '#E6F003'; // Yellow
                //             break;
                //         default:


                //             greenA.push(poligono)
                //             setGreen(greenA);
                //             break;
                //     }
                // }




                // poligono.setMap(map);
                // console.log(blue);
                // console.log(red);
                // console.log(green);
                // return /** @type {!google.maps.Data.StyleOptions} */ {
                //     // return{
                //     fillColor: color,
                //     fillOpacity: opacity,
                //     strokeColor: color,
                //     strokeWeight: stroke
                // };

                // map.data.features.add(feature);
                //         map.data.addListener('click', function (event) {

                //             var Disponible = false;

                //             let feature = event.feature.h;

                //             //   const lot = response.disponibilidad.find((disp) => disp.Nombre === lotName);

                //             let lote = response.disponibilidad.find(lt => lt.Nombre == feature.name);

                //             if (lote) {
                //                 if (lote.Estatus == 1) {
                //                     Disponible = true;
                //                 }
                //             }

                //             if (Disponible) {

                //                 // document.getElementById('login-content').classList.remove('hide');
                //                 // $('body').css({
                //                 //     'overflow': 'hidden'
                //                 // });

                // console.log(true)
                //                 ShowPrice(lote);
                //             }

                //         });

            });
            // );
            console.log(map.data.features);
            setMap(map);
            setLoading(false);

            let load = document.getElementById("loading");
            load.remove();
            // initInstrucctions();

        }
        else {
                setLoading(true);
        }

        setMap(map)
        // add event listener for click event
        // document.getElementById("restore-overlay").addEventListener("click", restoreOverlay);
        // document.getElementById("remove-overlay").addEventListener("click", removeOverlay);

        // let azul = document.getElementById("azul");
        // console.log(azul);
        // azul.target.checked = true;
        // console.log(azul);
        //debugger 
        // console.log(map);
        // let OverlayBlue = new window.google.maps.GroundOverlay(
        //     blue.map((pol) => {
        //         pol.setMap(pol);
        //     }),
        //     map.getBounds()
        // );

        // console.log(OverlayBlue);
        // console.log(map);



        // OverlayBlue.setMap(map);
        // setOverlayBlue(OverlayBlue);
        // restore overlay to map
        //restoreOverlay();

    }, [])


    function eventClic() {
        map.data.addListener('click', function (event) {

            var Disponible = false;

            let feature = event.feature.h;

            //   const lot = response.disponibilidad.find((disp) => disp.Nombre === lotName);

            let lote = response.disponibilidad.find(lt => lt.Nombre == feature.name);

            if (lote) {
                if (lote.Estatus == 1) {
                    Disponible = true;
                }
            }

            if (Disponible) {

                // document.getElementById('login-content').classList.remove('hide');
                // $('body').css({
                //     'overflow': 'hidden'
                // });


                ShowPrice(lote);
            }

        });

    }

    function ShowPrice(data) {
        // if (pathString.includes('/es/')) {
        //     idioma = 'es';
        // } else if ('/en/') {
        //     idioma = 'en';
        // }

        //console.log(data,"TopoJson");
        // $('html').animate({
        //     scrollTop: $("#Section_Maps").offset().top
        // }, 1000);

        var detailsMaps = document.getElementById('detailsMaps');

        if (detailsMaps == null) {
            var createNewDiv = document.getElementById('map');
            //create new div
            var newDiv = document.createElement('div');
            //add params id at newDiv
            newDiv.setAttribute('id', "detailsMaps");
            //add new div at Master div
            createNewDiv.appendChild(newDiv);
            //Select again div
            detailsMaps = document.getElementById('detailsMaps');
        }

        //limit ft decimal
        var ft = (data.TerrenoM2 * 10.76391042).toFixed(2);
        var area = data.TerrenoM2 + ' m<span class=\"measure-unit\">2</span>' + ' ' + '/' + ft + ' ft²';
        var precio = new Intl.NumberFormat("en-US").format(data.Importe);
        var idLotes = data.Nombre;

        // definir si es residencian o comercial y texto previo a términos y condiciones R.M. 31.03.2020
        var tipoLote = data.Tipo.toLowerCase();
        // if (tipoLote === 'residencial' && idioma === 'es') {
        //     tipoLote = 'Residencial';
        // } else if (tipoLote === 'residencial' && idioma === 'en') {
        //     tipoLote = 'Residential';
        // } else if (tipoLote === 'comercial' && idioma === 'es') {
        //     tipoLote = 'Comercial';
        // } else if (tipoLote === 'comercial' && idioma === 'en') {
        //     tipoLote = 'Commercial';
        // }

        var div = document.createElement('div');
        //div.innerHTML = '<div class="row animated zoomIn"><div class="col-xs-12"><div class="row"><div class="col-xs-10"><h4>' + data.Conjunto + '</h4></div><div class="col-xs-2"><a onclick=cleanShowPrice()><h4>X</h4></a></div><div class="col-xs-12"><h5 class="bold-black-text"> ' + diccionarioScripts.Manzana + ' ' + data.Manzana + ' / ' + diccionarioScripts.Lote + ' ' + data.Lote + ' <span id=idLotes style=opacity:0>' + idLotes + '</span></h5><hr class="hr-color"></div></div></div><div class="col-xs-12"><h5 class="bold-black-text">' + tipoLote + '</h5></div><div class="col-xs-4"><h5>' + diccionarioScripts.Medida + ':</h5><h5>' + diccionarioScripts.Precio + ':</h5></div><div class="col-xs-7"><h5>' + area + '</h5><h5> $' + precio + ' usd</h5></div><br><div class="col-xs-12"><a onclick=SendProcesignShopping() class="box-shadow btn btn-block btn-primary view-cotiza">' + diccionarioScripts.OpcionesCompra + '</a></div><div class="col-xs-12"><br><p>*' + diccionarioScripts.ResticcionesCambios + '.</div></div>';
        div.innerHTML = '<div class="animated zoomIn"><div class="col-xs-12"><div class="row"><div class="col-xs-10"><h4>' + data.Conjunto + '</h4></div><div class="col-xs-2" style="padding-left:0px;"><a onclick=cleanShowPrice()><h4>X</h4></a></div><div class="col-xs-12"><h5 class="bold-black-text"> ' + "Manzana" + ' ' + data.Manzana + ' / ' + "Lote" + ' ' + data.Lote + ' <span id=idLotes style=opacity:0>' + idLotes + '</span></h5><hr class="hr-color"></div></div></div><div class="col-xs-12"><h5 class="bold-black-text">' + tipoLote + '</h5></div><div class="col-xs-12 divSupPrecio"><h5 class="col-xs-5">' + "Medida" + ':</h5><h5>' + area + '</h5></div><div class="col-xs-12 divSupPrecio" ><h5 class="col-xs-5">' + "Precio" + ':</h5><h5> $' + precio + ' usd</h5></div><br><div class="col-xs-12"><br><p>*' + "ResticcionesCambios" + '.</div></div>';


        if (counterOpenShowPrice == 1 && detailsMaps.childNodes.length >= 1) {
            var RemplaceDiv = detailsMaps.childNodes[0];
            detailsMaps.replaceChild(div, detailsMaps.childNodes[0]);
            map.controls[window.google.maps.ControlPosition.RIGHT_TOP].setAt(newDiv);
        } else {
            detailsMaps.appendChild(div)
            map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(detailsMaps);
            setCounterOpenShowPrice(1);
        }
        // document.getElementById('login-content').classList.add('hide');
        // $('body').css({
        //     'overflow': 'visible'
        // });
    }

    function initInstrucctions() {

        var instrucccion = document.getElementById('instrucction');

        if (instrucccion == null) {
            var createLegendDiv = document.getElementById('map');
            instrucccion = document.createElement('div');
            instrucccion.setAttribute('id', "instrucction");
            createLegendDiv.appendChild(instrucccion);
            var newMessagge = document.createElement('div');;
            newMessagge.innerHTML = '<p class="capture-leyenda">haga clic en un lote disponible para iniciar su compra on line</p >';
            instrucccion.appendChild(newMessagge);
        }

        // map.controls[4].push(instrucccion);

    }




    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)

    }, [])
    function restoreOverlay(color) {
        let array = [];
        let newColor = "";
        switch (color) {
            case "blue":
                array = blue;
                newColor = "#0D0D9B"
                break;
            case "red":
                array = red;
                newColor = "#9B0D0D"
                break;
            case "green":
                array = green;
                newColor = "#14993C"
                break;
            case "yellow":
                array = yellow;
                newColor = "#E6F003"
                break;

        }


        array.map((pol) => {
            let opt = pol.opt;
            opt.fillColor = newColor;
            opt.strokeColor = newColor;
            pol.pol.setOptions(opt)
        })
    }

    function removeOverlay(color) {

        let array = [];
        // let newColor= "";
        switch (color) {
            case "blue":
                array = blue;
                // newColor = "#0D0D9B"
                break;
            case "red":
                array = red;
                // newColor = "#9B0D0D"
                break;
            case "green":
                array = green;
                // newColor = "#14993C"
                break;
            case "yellow":
                array = yellow;
                // newColor = "#E6F003"
                break;

        }

        array.map((pol) => {
            let opt = pol.opt;
            // opt.fillColor = "#00000000"
            opt.fillColor = "#F1F1F1";
            opt.strokeColor = "#CFCFCF"
            pol.pol.setOptions(opt);
        })

    }


    //     useEffect(() => {
    //         debugger

    //         console.log(blueView)

    //         // const mapa = new window.google.maps.Polygon()

    //         blue.map((pol)=>{
    //             pol.setMap(null);

    //         })
    //         // map.data.Polygon.
    // // setMap(map)
    //         // window.initMap()

    //     }, [blueView])
    // const check= React.ChangeEvent(function ChangeEvent(check)) {

    // }
    const onChange = React.useCallback(function callback(check) {
        console.log(check)

        if (check.target.checked) {
            restoreOverlay(check.target.id);
        } else {
            removeOverlay(check.target.id);
        }


    }, [])
    const options = {
        streetViewControl: false,
        zoom: 16,
        minZoom: 13,
        maxZoom: 17,
        heading: 90,
        mapTypeControl: true,
        mapTypeControlOptions: false,
        fullscreenControl: false,
        zoomControlOptions: {
            position: 8
        },
        scaleControl: false,
        zoomControl: true,
        gestureHandling: 'cooperative',
        // mapTypeId: "terrain",
        mapTypeId: "hybrid",
    }
    return isLoaded ? (
        <React.Fragment>
            <Helmet>
                {/* <script async defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBO0BcpUOaQ1ZXwwMzCX2hKYIO1uKdKVrM"></script> */}
            </Helmet>
            {/* // return isLoaded ? ( */}
            <Grid style={{ marginTop: "50px", width: "100%", height: "100%" }}>
                {/* <div>
                    <button onClick={(() => { setBlueView(true) })}>Azul</button>
                </div> */}

                <div id="floating-panel">
                    {/* <input id="remove-overlay" type="button" value="Remove overlay" />

                    <input id="restore-overlay" type="button" value="Restore overlay" /> */}
                    <Checkbox onChange={onChange} id="blue" defaultChecked={true} /><label>Azules</label>
                    <Checkbox onChange={onChange} id="red" defaultChecked={true} /><label>Rojos</label>
                    <Checkbox onChange={onChange} id="green" defaultChecked={true} /><label>Verdes</label>
                    <Checkbox onChange={onChange} id="yellow" defaultChecked={true} /><label>Amarillos</label>
                </div>
                <GoogleMap id="map"
                    options={options}
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                    // center={center}
                    zoom={16}
                    minZoom={14}
                    maxZoom={16}

                    onLoad={onLoad}
                    onUnmount={onUnmount}
                >
                    { /* Child components, such as markers, info windows, etc. */}
                    <React.Fragment>
                        <Box id="loading" style={{
                            position: "absolute",
                            width: "100%",
                            display: "flex",
                            /* top: 50%; */
                            zIndex: "10000000",
                            height: "100%",
                            background: "#00000080"
                        }}>
                            <CircularProgress size={100} style={{
                                display: "flex",
                                // width: "40px",
                                justifyContent: "center",
                                // height: "40px",
                                margin: "auto",
                                alignItems: "center",
                                /* height: 100%; */
                                color: "#FFFFFF",
                                zIndex: "10000000",
                                alignContent: "center"
                            }} />
                        </Box>

                        <section class="layout-mapNew" id="Section_Maps">
                            <h2 class="text-center">Disponibilidad</h2>
                            <div class="google-maps" id="div_Map">
                                <div id="compass" style={{ marginRight: "10px" }}>
                                    <img src="https://testga.blob.core.windows.net/media/41834/north.png" alt="compas" />{/*  className="img-responsive" */}
                                </div>

                                <div id="google-map"></div>
                                <div id="detailsMaps"></div>
                                <div id="legend"></div>
                                <div id="location"></div>
                                <div id="instrucction">

                                    <p class="capture-leyenda">
                                        <img src="https://testga.blob.core.windows.net/media/42616/click.gif" alt="click sobre el lote disponible" style={{ maxHeight: "60px" }} /><br />
                                        Haga clic en un lote disponible para iniciar su compra online
                                    </p>
                                </div>
                            </div>
                        </section>



                    </React.Fragment>
                    {/* <KmlLayer 
        options={{ preserveViewport : false}}
    /> */}
                </GoogleMap>
            </Grid>
        </React.Fragment>
    ) : <></>
    // }

    // );
}
