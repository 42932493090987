// extracted by mini-css-extract-plugin
export var containerItemAreaTotal = "modelo-module--container-item-area-total--ZYREy";
export var divIframe = "modelo-module--divIframe--19Nrr";
export var feature = "modelo-module--feature--HvN-A";
export var features1 = "modelo-module--features1--3y9-E";
export var featuresCont = "modelo-module--featuresCont--hdpnV";
export var iconModelLeft = "modelo-module--icon-model-left--+MgAA";
export var iconModelRight = "modelo-module--icon-model-right--1JV93";
export var iframe = "modelo-module--iframe--TH6+7";
export var itemsModelsMobile = "modelo-module--items-models-mobile--T2soC";
export var mainImg = "modelo-module--mainImg--pdTlj";
export var mainInfo = "modelo-module--mainInfo--cNAuy";
export var modeloCont = "modelo-module--modeloCont--QcnPm";
export var modeloImgs = "modelo-module--modeloImgs--tMyLG";
export var modeloIn = "modelo-module--modeloIn---WV9q";
export var modeloMain = "modelo-module--modeloMain--F0LV4";
export var paddingColumnModel = "modelo-module--padding-column-model--eqHvF";
export var paddingDescriptionModel = "modelo-module--padding-description-model--STj+O";
export var planta = "modelo-module--planta--TbPV4";
export var plantasCont = "modelo-module--plantasCont--JE2xx";
export var titleModelo = "modelo-module--title-modelo--f8Db4";
export var titleModeloName = "modelo-module--title-modelo-name--bY1jO";
export var titlePriceModelo = "modelo-module--title-price-modelo--xxBrV";
export var tyc = "modelo-module--tyc--BQ7tf";
export var warningTextDescriptionModel = "modelo-module--warning-text-description-model--wfr0F";